@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --sat: env(safe-area-inset-top);
  --sar: env(safe-area-inset-right);
  --sab: env(safe-area-inset-bottom);
  --sal: env(safe-area-inset-left);
  --fill-height: env(-webkit-fill-available);
}

@layer utilities {
  .safearea-pad-y {
    padding-top: env(safe-area-inset-top, 0);
    padding-bottom: env(safe-area-inset-bottom, 0);
  }
  .safearea-pad-top {
    padding-top: env(safe-area-inset-top, 0);
  }
  .safearea-pad-bot {
    padding-bottom: env(safe-area-inset-bottom, 0);
  }

  .safearea-spacer-top {
    height: env(safe-area-inset-top, 0);
  }
  .safearea-spacer-bot {
    height: env(safe-area-inset-bottom, 0);
  }

  .toast-force-safe-area-top {
    top: max(env(safe-area-inset-top, 0), 16px) !important;
  }

  .h-safe-screen-desktop {
    height: 100vh;
    min-height: 100vh;
    max-height: 100vh;
  }

  /* For content that you want as the exact height of the screen
  Typically for child content that scrolls */
  .h-safe-screen {
    height: stretch;
    height: fill-available;
    height: -moz-available;
    height: -webkit-fill-available;

    min-height: fill-available;
    min-height: -moz-available;
    min-height: -webkit-fill-available;

    max-height: 100vh;

    @apply sm:h-safe-screen-desktop;
  }
}

@layer components {
  .label-base {
    @apply block text-sm font-medium leading-6 text-text-label-lightmode dark:text-text-label-darkmode;
  }
  .label-form {
    @apply label-base mb-1;
  }

  .input-base {
    @apply block w-full rounded-md border-0 border-none;
    @apply disabled:cursor-not-allowed disabled:opacity-50;
    @apply focus:outline-none focus:ring-2 focus:ring-ring-active focus:ring-offset-2;
  }
  .input-lightmode {
    @apply border-border-primary-lightmode bg-bg-input-lightmode text-text-primary-lightmode;
  }
  .input-darkmode {
    @apply border-border-primary-lightmode bg-bg-input-darkmode text-text-primary-lightmode;
  }
  .input-base-form {
    @apply input-base input-lightmode dark:input-darkmode;
  }
  .input-form {
    @apply input-base-form rounded-lg px-3.5 py-2;
  }
}

html,
body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  font-family: 'Inter', apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial,
    sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  @apply text-text-primary-lightmode dark:text-text-primary-darkmode;
  @apply bg-bg-primary-lightmode dark:bg-bg-primary-darkmode;

  /* Make it full height */
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-height: 100vh;

  /* NOTE: This is to remove the transparent blue box on tap to feel more native mobile */
  /* This is most needed in a capacitor application */
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

html.dark,
.dark body {
  @apply text-text-primary-darkmode;
  @apply bg-bg-primary-darkmode;
}

::selection {
  background-color: #3ddc60;
  color: white;
}

@media only screen and (max-width: 639px) {
  html,
  body {
    /* mobile viewport bug fix for Safari */
    min-height: -webkit-fill-available;
  }
}

* {
  box-sizing: border-box;
}

#__next {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

html.dark.gizmo body {
  background-color: rgba(52, 53, 65, 1);
}
